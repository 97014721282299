import DynamicPage from "components/pages/dynamic/dynamic-page.vue";
import { createApp } from "vue";
import { mountApp } from "vue-app";

const app = createApp(DynamicPage,
    {
        cmsPageEndpointPath: "/why-virtuoso"
    }
);
mountApp(app, "page-app");
